export default {
  limit: (el, data) => {
    let str = el.innerText;
    // 如果传了upper修饰符，则转成大写
    if (data.modifiers.upper) str = str.toUpperCase()
    // 如果字数超过限定，则截取
    if (str.length > data.value) {
      el.title = str;
      el.innerText = str.substring(0, data.value) + (data.modifiers.point?"...":"");
    }
  },
  color: (el, data) => {
    el.style.color = data.value;
  },
};
