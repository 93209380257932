import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    collapsed: false,
    isMobile: false
  },
  mutations: {
    changeCollapsed(state){
      state.collapsed = !state.collapsed
    },
  },
});

export default store;
