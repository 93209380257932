// import Vue from "vue";
// Vue.prototype.ax = axios
import mixin from "./mixin";
import filter from "./filter";
import directive from "./directive";
import component from "./component";
import "./css/ozhas.css";

let plugin = {
  install(Vue,options={}) {
    //全局混入
    Vue.mixin(mixin);
    //注册所有全局组件
    Object.keys(component).forEach((key) => {
      Vue.component(key, component[key]);
    });
    //注册所有全局指令
    Object.keys(directive).forEach((key) => {
      Vue.directive(key, directive[key]);
    });
    //注册所有全局过滤器
    Object.keys(filter).forEach((key) => {
      Vue.filter(key, filter[key]);
    });
    // for (const key in filter) {
    //   Vue.filter(key,filter[key])
    // }

    if(!options.slient){
      console.log('-'.repeat(50));
      console.log('OZHAS-PLUGIN加载成功');
    }
  },
};

export default plugin;
