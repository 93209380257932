import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
//路由
import router from './routes'
//vuex
import store from './store'
//plugin
import plugin from './plugin'
Vue.use(plugin,{slient:true})
//ant-design
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
//nprogress
import 'nprogress/nprogress.css'
//导入i8n国际化
import i18n from './locale'
//导入打印组件
import print from 'vue-print-nb'
Vue.use(print)

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
