export default {
  message: {
    hello: "hi Hello",
  },
  label:{
    addOrder:"add Order"
  },
  nice: "good",
  leftside:{
    user_center:'UserCenter'
  }
};
