import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import routes from "./routes";
import nprogress from "nprogress";

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login") {
    if (!sessionStorage.getItem("login-user")) {
      return next("/login");
    }
  }
  nprogress.start();
  if (to.meta && to.meta.title) document.title = to.meta.title;
  next();
});

router.afterEach(() => {
  nprogress.done();
});

export default router;
