export default [
  {
    path: "/",
    component: () => import("~v/LayoutView"),
    children: [
      {
        path: "/",
        meta: {
          title: "数据统计",
          icon: "pie-chart",
          hide: false,
          type: "home",
        },
        component: () => import("~v/HomeView"),
      },
      {
        path: "/user",
        meta: {
          title: "用户管理",
          icon: "team",
          hide: false,
          type: "user",
        },
        component: () => import("~v/UserList"),
      },
      {
        path: "/role",
        meta: {
          title: "角色管理",
          icon: "solution",
          hide: false,
          type: "user",
        },
        component: () => import("~v/RoleList"),
      },
      {
        path: "/banner",
        meta: {
          title: "轮播图管理",
          icon: "fund",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Banner"),
      },
      {
        path: "/banner/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加轮播图",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Banner/AddOrUpdate"),
      },
      {
        path: "/type",
        meta: {
          title: "分类管理",
          icon: "dropbox",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Type"),
      },
      {
        path: "/type/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加分类",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Type/AddOrUpdate"),
      },
      {
        path: "/category",
        meta: {
          title: "类别管理",
          icon: "ordered-list",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Category"),
      },
      {
        path: "/category/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加类别",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Category/AddOrUpdate"),
      },
      {
        path: "/teacher",
        meta: {
          title: "教师管理",
          icon: "user",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Teacher"),
      },
      {
        path: "/teacher/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加教师",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Teacher/AddOrUpdate"),
      },
      {
        path: "/course",
        meta: {
          title: "课程管理",
          icon: "book",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Course"),
      },
      {
        path: "/course/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加课程",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Course/AddOrUpdate"),
      },
      {
        path: "/course/episode/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "课程详情",
          hide: true,
        },
        component: () => import("~v/Course/CourseDetail"),
      },
      {
        path: "/episode/add/:id/:media_type",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加或更新片段",
          hide: true,
        },
        component: () => import("~v/Course/AddOrUpdateEpisodes"),
      },
      {
        path: "/vip",
        meta: {
          title: "会员管理",
          icon: "crown",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Vip"),
      },
      {
        path: "/vip/add/:id",
        props:true,
        meta: {
          title: "添加价格",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Vip/AddOrUpdate"),
      },
      {
        path: "/comment",
        meta: {
          title: "评论管理",
          icon: "edit",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Comment"),
      }
    ],
  },
  {
    path: "/login",
    meta: {
      title: "登录页面",
      icon: "logout",
      hide: false,
      type: "logout",
    },
    component: () => import("~v/LoginView"),
    children: [
      {
        path: "/",
        meta: {
          hide: false,
          type: "logout",
        },
        component: () => import("~v/User"),
      },
    ]
  },
];
